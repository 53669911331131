import { PostgrestResponse, Session } from '@supabase/supabase-js'
import {
  Freguesia,
  IpeUser,
  IpeError,
  Municipio,
  Pedido,
  PedidoContratoEventual,
  PedidoPontoLuz,
  PedidoPoste,
  Permission,
} from '@models/models/models.types'

export const EVENTS = {
  PASSWORD_RECOVERY: 'PASSWORD_RECOVERY',
  SIGNED_OUT: 'SIGNED_OUT',
  USER_UPDATED: 'USER_UPDATED',
  SIGNED_IN: 'SIGNED_IN',
  INITIAL_SESSION: 'INITIAL_SESSION',
}

export const VIEWS = {
  SIGN_IN: 'sign_in',
  SIGN_UP: 'sign_up',
  FORGOTTEN_PASSWORD: 'forgotten_password',
  MAGIC_LINK: 'magic_link',
  UPDATE_PASSWORD: 'update_password',
}

export interface SupabaseProps {
  session: Session | null
  user: IpeUser | null | undefined
  children: React.ReactNode
}

export interface ISupabaseContext {
  session: Session | null
  user: IpeUser | null | undefined
  view: string
  actions: {
    createPedido: (pedido: PedidoPontoLuz | PedidoPoste | PedidoContratoEventual) => Promise<PostgrestResponse<Pedido>>
    updatePedido: (pedido: PedidoPontoLuz | PedidoPoste | PedidoContratoEventual) => Promise<void>
    generateNextPedidoCode: () => Promise<string>
    uploadAnexoPedido: (pedido: Pedido, files: File[]) => Promise<void>
    fetchFreguesias: (municipio?: string | null | undefined) => Promise<Freguesia[]>
    fetchMunicipios: () => Promise<Municipio[]>
    inviteFreguesias: (freguesias: Freguesia[]) => Promise<void>
    inviteFreguesia: ({ id, email }: Pick<Freguesia, 'id' | 'email'>) => Promise<void>
    inviteMunicipio: ({ id, email }: Pick<Municipio, 'id' | 'email'>) => Promise<void>
    updateEmailFreguesia: ({ id, email }: Freguesia) => Promise<void>
    updateMuncipio: (municipio: Municipio) => Promise<void>
    userCan: (requiredPermission: Permission) => boolean
    fetchUsers: () => Promise<IpeUser[]>
    inviteTecnico: ({ municipioId, email }: { municipioId?: string; email: string }) => Promise<void>
    getUserLocation: (
      freguesia_id: string | null | undefined,
      municipio_id: string | null | undefined
    ) => Promise<
      | {
          municipio: string | null | undefined
          freguesia: string | null | undefined
        }
      | undefined
    >
  }
  error: IpeError | null
}
