import { InviteUserData, IpeApiResponse, IpeError, Pedido, Role } from '@models/models/models.types'
import { Session, User } from '@supabase/supabase-js'
import { Table } from '@tanstack/react-table'
import { supabaseMiddlewareClient, supabaseServerClient } from '@utils/client'
import axios, { AxiosError } from 'axios'
import { Workbook } from 'exceljs'
import { NextApiRequest, NextApiResponse } from 'next'
import { BadRequestException, UnauthorizedException } from 'next-api-handler'
import { pathTo } from './router'
import { saveAs } from 'file-saver'
import { states } from './states'

export function tipoPedidoToString(
  tipoPedido: 'postes-e-luminarias' | 'luminarias' | 'contratos-eventuais' | undefined
) {
  switch (tipoPedido) {
    case 'contratos-eventuais':
      return 'Contrato Eventual'
    case 'luminarias':
      return 'Luminária(s)'
    case 'postes-e-luminarias':
      return 'Poste(s) e Luminária(s)'
    default:
      return ''
  }
}

export function tipoTensaoToString(tipoTensao: string | null) {
  switch (tipoTensao) {
    case 'com-baixa-tensao':
      return 'Com Baixa Tensão'
    case 'sem-baixa-tensao':
      return 'Sem Baixa Tensão'
    default:
      return ''
  }
}

export function aprovacaoToString(aprovacao: boolean | null) {
  switch (aprovacao) {
    case true:
      return 'Aprovado'
    case false:
      return 'Não Aprovado'
    default:
      return ''
  }
}

export function stateToString(state: string) {
  switch (state) {
    case 'novo':
      return 'Novo'
    case 'aguarda-pagamento-camara':
      return 'Aguarda Pgto. da Câmara'
    case 'aguarda-pagamento-freguesia':
      return 'Aguarda Pgto. da Junta'
    case 'solicitado-inicio-trabalhos-eredes':
      return 'Em execução'
    case 'concluido':
      return 'Concluído'
    case 'total':
      return 'Total'
    default:
      return ''
  }
}

export function convertStateToString(stateValue: string) {
  const state = states.filter(({ value }) => value === stateValue)

  if (state.length > 0) {
    return state[0].label
  }
}

export function dateFormat(date: string) {
  const dateType = new Date(date)

  return dateType.toLocaleDateString('pt')
}

export function addPermissionToEmail(role: Role, email: string) {
  let plusAddress = ''
  switch (role) {
    case 'administrador':
      plusAddress = '+ipe_administrador'
      break
    case 'tecnico':
      plusAddress = '+ipe_tecnico'
      break
    case 'freguesia':
      plusAddress = '+ipe_freguesia'
      break
    case 'dev':
      plusAddress = '+ipe_dev'
      break
    default:
      plusAddress = ''
      break
  }

  const splitted = email.split('@')
  const username = splitted[0]
  const domain = splitted[1]

  return `${username}${plusAddress}@${domain}`
}

export async function inviteEmail(emailWhoInvited: string, emailToInvite: string, userKindToInvite: string) {
  return axios
    .post<IpeApiResponse<InviteUserData>>(pathTo().api().email().convidar(), {
      email: emailToInvite,
      userKind: userKindToInvite,
      invitedBy: emailWhoInvited,
    })
    .then((res) => {
      return res.data
    })
    .catch((err) => {
      const error = err as AxiosError<IpeApiResponse<InviteUserData>>
      return { ...error.response?.data } as IpeApiResponse<InviteUserData>
    })
}

export async function sendNotificationByEmail(order: Pedido) {
  const data = order
  return axios
    .post(pathTo().api().email().notificar(), data)
    .then((res) => {
      return res
    })
    .catch((err) => {
      const error = err as AxiosError
      throw new Error(error.message)
    })
}

export type AuthMiddleWareResponse = {
  user: User
}

export async function authMiddleware(req: NextApiRequest, res: NextApiResponse) {
  const supabase = supabaseMiddlewareClient(req, res)
  const {
    data: { user },
    error,
  } = await supabase.auth.getUser()
  if (error) {
    console.error(error)
    throw new BadRequestException(error.message)
  }

  if (!user) {
    throw new UnauthorizedException()
  }

  return { user }
}

export function getHeader(column: any) {
  if (column.totalHeaderCount == 1) {
    return [
      {
        value: column.Header,
        type: 'string',
      },
    ]
  }

  const span = [...Array(column.totalHeaderCount - 1)].map((x) => ({
    value: '',
    type: 'string',
  }))

  return [
    {
      value: column.Header,
      type: 'string',
    },
    ...span,
  ]
}

export async function exportToXlsx(table: Table<any>, filename: string, applyFilters = true) {
  const wb = new Workbook()
  const ws = wb.addWorksheet('Sheet 1')

  const lastHeaderGroup = table.getHeaderGroups().at(-1)
  console.debug('headerGroups', table.getHeaderGroups())

  if (!lastHeaderGroup) {
    console.error('No header group found!', table.getHeaderGroups())
    return
  }

  console.debug('lastHeaderGroup', lastHeaderGroup)

  ws.columns = table
    .getHeaderGroups()
    .flatMap((e) => e.headers)
    .filter(
      (h) => h.column.getIsVisible() //&& !(h.column.columnDef.meta as any)?.noExport
    )
    .map((header) => {
      console.debug('header', header.column.columnDef.header?.valueOf())
      const h = header.column.columnDef.header?.valueOf() as string | undefined
      const hh = h ?? ''
      return {
        header: hh,
        y: header.id,
      }
    })

  console.debug('columns', ws.columns)

  const exportRows = applyFilters ? table.getFilteredRowModel().rows : table.getCoreRowModel().rows
  console.debug('rows', exportRows)

  exportRows.forEach((row) => {
    const cells = row.getVisibleCells()
    // const exportCells = cells.filter(
    //   (cell) => !(cell.column.columnDef.meta as any)?.noExport
    // );
    const exportCells = cells
    const values = exportCells.map((cell) => cell.getValue() ?? '')
    console.debug('values', values)
    ws.addRow(values)
  })

  ws.getRow(1).eachCell((cell) => {
    cell.font = { bold: true }
  })

  // for csv we would use await wb.csv.writeBuffer();
  const buf = await wb.xlsx.writeBuffer()
  saveAs(new Blob([buf]), `${filename}.xlsx`)
}

const nonAsciiRegex = /[^a-zA-Z0-9._ ]/g

export function containsNonAscii(string: string) {
  return nonAsciiRegex.test(string)
}

export function sanitizeFilename(filename: string) {
  const sanitizedFilename = filename.replace(nonAsciiRegex, '')
  return sanitizedFilename
}

export const informativeMessage = (orderType: string) => {
  switch (orderType) {
    case 'contratos-eventuais':
      return 'Utilize esta página para enviar os documentos necessários para a realização de um contrato Eventual/Festividades.'
    case 'luminarias':
      return 'Utilize esta página para pedir a colocação ou manutenção de pontos de luz em postes existentes.'
    case 'postes-e-luminarias':
      return `Esta página destina-se a pedir ampliações da rede de iluminação pública (IP), ou rede de iluminação pública incluindo também rede de baixa tensão (BTN e IP).`
    default:
      return ''
  }
}
