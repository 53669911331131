export const states = [
  { value: 'novo', label: 'Novo' },
  { value: 'cancelado', label: 'Cancelado' },
  { value: 'em-analise', label: 'Em Análise' },
  { value: 'aguarda-orcamento-eredes', label: 'Aguarda Orçamento (E-Redes)' },
  { value: 'aguarda-parecer-tecnico', label: 'Aguarda Parecer Técnico' },
  { value: 'aguarda-aprovacao-camara', label: 'Aguarda Aprovação da Câmara' },
  { value: 'aguarda-pagamento-camara', label: 'Aguarda Pagamento da Câmara' },
  { value: 'cancelado-orcamento-expirado', label: 'Cancelado Orçamento Expirado' },
  { value: 'aguarda-pagamento-freguesia', label: 'Aguarda Pagamento da Junta' },
  { value: 'solicitado-inicio-trabalhos-eredes', label: 'Solicitado Início dos trabalhos (E-Redes)' },
  { value: 'obra-suspensa', label: 'Obra Suspensa' },
  { value: 'concluido', label: 'Concluído' },
]
