import { CookieOptions, createBrowserClient, createServerClient, serialize } from '@supabase/ssr'
import { Database } from './database.types'
import { getCookie, setCookie } from 'cookies-next'
import { GetServerSidePropsContext, NextApiRequest, NextApiResponse } from 'next'

const url = process.env.NEXT_PUBLIC_SUPABASE_URL
const annonKey = process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY

if (url == null) {
  throw Error('missing NEXT_PUBLIC_SUPABASE_URL value')
}

if (annonKey == null) {
  throw Error('missing NEXT_PUBLIC_SUPABASE_ANON_KEY')
}

export const supabaseBrowserClient = () => {
  return createBrowserClient<Database>(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
    {
      cookies: {
        get(name: string) {
          return getCookie(name)
        },
        set(name: string, value: string, options: CookieOptions) {
          setCookie(name, value, { ...options })
        },
        remove(name: string, options: CookieOptions) {
          setCookie(name, '', { ...options })
        },
      },
    }
  )
}

export const supabaseServerSidePropsClient = (context: GetServerSidePropsContext) => {
  return createServerClient<Database>(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
    {
      cookies: {
        get(name: string) {
          return getCookie(name, { req: context.req, res: context.res })
        },
        set(name: string, value: string, options: CookieOptions) {
          context.res.setHeader('Set-Cookie', serialize(name, value, options))
          setCookie(name, value, {
            ...options,
            req: context.req,
            res: context.res,
          })
        },
        remove(name: string, options: CookieOptions) {
          context.res.setHeader('Set-Cookie', serialize(name, '', options))
          setCookie(name, '', { ...options, req: context.req, res: context.res })
        },
      },
    }
  )
}

export const supabaseServerClient = () => {
  return createServerClient<Database>(
    process.env.NEXT_PUBLIC_SUPABASE_URL!,
    process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!,
    {
      cookies: {
        get(name: string) {
          return getCookie(name)
        },
        set(name: string, value: string, options: CookieOptions) {
          setCookie(name, value, { ...options })
        },
        remove(name: string, options: CookieOptions) {
          setCookie(name, '', { ...options })
        },
      },
    }
  )
}

export const supabaseMiddlewareClient = (req: NextApiRequest, res: NextApiResponse) => {
  return createServerClient(process.env.NEXT_PUBLIC_SUPABASE_URL!, process.env.NEXT_PUBLIC_SUPABASE_ANON_KEY!, {
    cookies: {
      get(name: string) {
        return getCookie(name, { req, res })
      },
      set(name: string, value: string, options: CookieOptions) {
        res.setHeader('Set-Cookie', serialize(name, value, options))
        setCookie(name, value, { req, res, ...options })
      },
      remove(name: string, options: CookieOptions) {
        res.setHeader('Set-Cookie', serialize(name, '', options))
        setCookie(name, '', { req, res, ...options })
      },
    },
  })
}

export const supabaseAdminMiddlewareClient = (req: NextApiRequest, res: NextApiResponse) => {
  return createServerClient(process.env.NEXT_PUBLIC_SUPABASE_URL!, process.env.NEXT_SUPABASE_SERVICE_KEY!, {
    cookies: {
      get(name: string) {
        return getCookie(name, { req, res })
      },
      set(name: string, value: string, options: CookieOptions) {
        res.setHeader('Set-Cookie', serialize(name, value, options))
        setCookie(name, value, { req, res, ...options })
      },
      remove(name: string, options: CookieOptions) {
        res.setHeader('Set-Cookie', serialize(name, '', options))
        setCookie(name, '', { req, res, ...options })
      },
    },
  })
}
